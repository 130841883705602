import chaga from "../images/chaga.webp";
import cordy from "../images/cordy.webp";
import maitake from "../images/maitake.webp";
import reishi from "../images/reishiS(1).webp";
import turkey from "../images/turkey1.webp";
import lions from "../images/Group 348.webp";
import smallR from "../images/Reishi-green.svg";
import smallChaga from "../images/Chaga-green.svg";
import smallCordy from "../images/-green.jpg";
import smallMaitake from "../images/Maitake-green.svg";
import smallTT from "../images/TurkeyTail-green.svg";
import smallLM from "../images/lions-green.png";

export const aboutShrooms = [
  {
    type: "Lion's mane",
    link: "lionsmane",
    lat: "Hericium erinaceus",
    lat1: "[ hair-<b>ee</b>-see-uhm Air-<b>ee</bz-nass-ee-us ]",
    benefits1: [
      "Slowed neurodegeneration",
      "Improved cognitive function",
      "Neuron growth",
      "Increases mental clarity and focus",
    ],

    benefits2: [
      "Enhanced memory",
      "Boost in energy",
      "The Nervous System",
      "Healthy inflammatory response",
    ],

    perfect: [
      "Hiking",
      "Casual and not-so casual conversations",
      "Pre, during and post work",
      "Multitasking",
      "Midday pick me up",
    ],

    description: `One of nature’s most powerful nootropics, lion’s mane has been shown to shield your brain against the natural side effects that accompany aging.
     Lion’s mane has been shown to enhance mental clarity, improve cognitive function, aid digestion and protect your brain against neurodegeneration. All in all, lion's mane gives your brain a boost and the protection it deserves.`,
    funfact: [
      "Also known as the <i>bearded tooth</i> or the <i>pom pom</i> mushroom.",
      "A small study showed promising results suggesting that lion’s mane may  benefit individuals with Alzheimer’s disease.",
      // "Currently being researched as a potential treatment for diseases such as dementia and Alzheimer's.",
      "Due to its texture, consistency and flavor profile, lion’s mane is a delicous substitute for crab and other types of seafood.",
    ],
    image: lions,
    image1: smallLM,
  },

  {
    type: "Reishi",
    link: "reishi",
    lat: "Ganoderma lucidum",
    lat1: "[gan-uh-der-ma Lee-ng-zee]",
    benefits1: [
      "Increased cognitive function",
      "The nervous system",
      "Protecting the body against viruses, bacteria, parasites etc.",
      // "Provides a potent punch of antioxidants",
      "The cardiovascular system",
    ],

    benefits2: [
      "The immune system",
      "Relaxation and assisting the body in adapting to stress",
      "Optimal antiviral and and anti-inflammatory properties to boost the immune system",
      "Improved sleep and circulation",
    ],

    perfect: [
      "Recovery (emotional and/ or athletic)",
      "Meditating",
      "Post work, post breakup, post…. anything",
      "Before bed",
    ],

    description:
      "Reishi is the jack of all trades. From supporting healthy blood sugar levels to enhancing the circulatory system, reishi is your body’s best friend. It’s also been known to maintain healthy immunity, assist in protecting and detoxifying the body, aid liver function and contribute to a healthy cardiovascular system.",
    funfact: [
      ' Reishi is also known as the “Elixir of Immortality."',
      " Reishi has been used in medicine for thousands of years. ",
      " Previously reishi was so rare that it was reserved only for royalty. ",
      " The Chinese name for Reishi is “lingzhi” which breaks down to  “ling” meaning divine, and zhi- of longevity. ",
      " Reishi respires just like humans, oxygen in and carbon dioxide out. When it is consumed it adds oxygen to the body.",
    ],
    image: reishi,
    image1: smallR,
  },

  {
    type: "Chaga",
    link: "chaga",
    lat: "Inonotus obliquus",
    lat1: "[in-on-oh-tus Ohb-lee-kuss]",
    benefits1: [
      "The immune system",
      "Balanced blood sugar levels",
      "Oxidative balance in the body",
      "Slowed cellular aging",
      "Optimal blood sugar and cholesterol levels",
    ],

    benefits2: [
      "Protection for the skin from aging",
      "Healthy inflammatory response",
      "Digestion",
      "Overall wellness as it contains a wide range of vitamins and minerals from Vitamin B to Zinc",
    ],

    perfect: [
      "When you’re trying to avoid or feel a cold coming on",
      "With your morning routine or a midday pick me up",
      "To keep your digestive system digesting",
    ],

    description: `Chaga is a potent source of beta-glucans and triterpenes; 
    which essentially means that it keeps you healthy and helps ward off colds and infections. 
    You can find these big hunks of mycelial mass growing on birch trees in cold climates, such as: 
    the forests of Alaska, Northern Canada, Northern Europe, Korea, Russia and Siberia. 
    It promotes a healthy immune response, packs a punch of antioxidants and supports healthy body function and digestion.`,

    funfact: [
      "Known as the “King of Mushrooms” or the “Diamond of the Forest.",
      "Once extracted, it is one of the highest sources of antioxidants in the world!",
      "Chaga is most commonly found on birch trees and takes 15-20 years to reach full maturity! During this time, it absorbs vitamins and minerals from the trees, and after extracting these elements, they are bio-available to the human body. (*We...) sustainably source our chaga and no trees are harmed in the harvesting process.",
      "Chaga is not technically a mushroom, though it is often referred to as one. Rather, it is a hardened mass of mycelium (the under-layer of growth that produces mushrooms).",
      "Chaga’s DNA is 30% closer to human DNA than it is to other plants! ",
    ],

    image: chaga,
    image1: smallChaga,
  },

  {
    type: "Turkey Tail",
    link: "turkeytail",
    lat: "Trametes versicolor",
    lat1: "[tra-meets Vair-see-cahl-ur]",
    benefits1: [
      "Healthy immune response",
      "Gut health",
      "Fighting off infections due to a healthy punch of beta-glucans",
      "Cellular growth and normal cell turnover",
    ],

    benefits2: [
      "Managing stress and fatigue",
      "Healthy blood pressure",
      "Healthy inflammatory response",
      "Reproductive health",
    ],

    perfect: [
      "All things gut health",
      "Ward off the common cold ",
      "Creates antibodies (for the record we're pro all bodies)",
    ],

    description:
      "Give that immune system of yours a hug. Turkey tail is packed with beta-glucans, PSK (polysaccharide-k) and PSP (polysaccharide peptide) to support the body to fight off infections. Turkey tail is another mushroom that's been used to remedy various illnesses, and let's be real, it really does look like a turkey tail.",

    funfact: [
      "A 2012 study found that a daily dose of turkey tail improved immune function in women with breast cancer.",
      "While most commonly found in North America, these mushrooms can be found all over the world.",
      "Turkey tail contains a potent source of polysaccharide K, which has been shown to boost the immune system to fight infection amongst other benefits.",
    ],
    image: turkey,
    image1: smallTT,
  },

  {
    type: "Cordyceps",
    link: "cordyceps",
    lat: "Cordyceps militaris",
    lat1: "[kor-di-seh-ps Mil-i-tah-riss]",
    benefits1: [
      "Natural energy levels",
      "Activating the body’s own energy production in cells, endurance and stamina",
      "Promoting healthy adrenal function and kidney health",
      "A healthy libido",
    ],

    benefits2: [
      "Improves the body’s lung capacity and supports a healthy respiratory system",
      "Boosting athletic performance",
      "The body’s natural metabolism",
      "General immunity",
    ],

    perfect: ["Preworkout ", "Sex positive mushroom", "Keeps the party going "],
    description: `There’s not much this mushroom doesn’t do. This versatile species boosts energy, 
      improves stamina and aids the cardiovascular system — it's no wonder why cordyceps are so sought after. 
      Your perfect companion for any kind of endurance ;) or simply maintaining a healthy body. 
      Welcome to the world of cordyceps.`,

    funfact: [
      "Traditionally grown on caterpillars in the mountainous regions of China, cordyceps are now cultivated using vegan farming practices. *Please note that no animals were harmed in our harvesting as we were able to reproduce these mushrooms in a lab ensuring our product is fully vegan-friendly.",
      "There are over 400 species of cordyceps! ",
      "At the Chinese National Games in 1993, a group of female athletes from Northeast China won a series of medals and broke 17 world records. They later revealed their secret weapon to be cordyceps.",
      "They look like cheetos when dried.",
    ],

    image: cordy,
    image1: smallCordy,
  },

  {
    type: "Maitake",
    link: "maitake",
    lat: "Grifola frondosa",
    lat1: "[gree-foh-la Fron-doh-sah]",
    benefits1: [
      "Metabolic functions and cellular health ",
      "Optimal blood sugar and cholesterol levels",
    ],

    benefits2: [
      "Modulating glucose levels to increase insulin production",
      "Overall health and weight loss",
    ],
    perfect: [
      "Body balance",
      "Give your metabolism some love",
      "Cold season",
      "A superb meat replacement #MaitakeMondays",
    ],
    description:
      "Known as the “Hen of the Woods,” maitake doesn’t pick favorites. It contains polysaccharides that help regulate blood sugar and metabolism, delivering antioxidants, alpha and beta-glucans to help the body fight off infections. Not only is it an essential medicinal mushroom, but it’s incredibly delicious. ",
    funfact: [
      "Translates to dancing mushroom in Japanese. It's said to have gotten its name after people danced with happiness upon finding it in the wild.",
      "Appropriately called hen of the woods, mistake is a delicious mushroom and a great substitute for chicken.",
      "Maitake is rich in copper, potassium, fiber and amino acids to name a few.",
    ],
    image: maitake,
    image1: smallMaitake,
  },
];
