import React, { useEffect } from "react";
import MeetYourShroomSingle from "../components/organisms/MeetYourShroomSingle/MeetYourShroomSingle";
import { PageTitle, SEO } from "../modules/seo";

interface ILocation {
  hash: string;
  host: string;
  hostname: string;
  href: string;
  key: string;
  origin: string;
  pathname: string;
  port: string;
  protocol: string;
  search: string;
  state: {
    component: {
      title: string;
    };
    key: string;
  };
}

const SingleShroom = ({ location }: { location: ILocation }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: "0 5%",
      }}
    >
      <SEO title={PageTitle.MeetYourShroomSingle} />
      <MeetYourShroomSingle
        type={location?.state?.component?.title}
        hash={location?.hash}
      />
    </div>
  );
};

export default SingleShroom;
