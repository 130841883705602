// extracted by mini-css-extract-plugin
export var benefits = "index-module--benefits--aJV-Z";
export var container = "index-module--container--XoI4g";
export var contentWrapper = "index-module--content-wrapper--ZF8Ok";
export var description = "index-module--description--1w65K";
export var disclaimer = "index-module--disclaimer--nCgHl";
export var imageWrapper = "index-module--image-wrapper--7u07u";
export var list = "index-module--list--N+UBf";
export var listWrapper = "index-module--list-wrapper--k27us";
export var perfect = "index-module--perfect--oqrZQ";
export var subsubtitle = "index-module--subsubtitle--MkMl9";
export var subtitle = "index-module--subtitle--AV4f0";
export var textWrapper = "index-module--text-wrapper--qmD64";
export var title = "index-module--title--8hD3H";