import React from "react";
import ShroomsLogo from "../../molecules/ShroomsLogo/ShroomsLogo";
import ShroomsMenu from "../../molecules/ShroomsMenu";
import AboutMushroom from "../../molecules/SingleShroom";
import * as s from "./MeetYourShroomSingle.module.scss";

const MeetYourShroomSingle = ({
  type,
  hash,
}: {
  type: string;
  hash: string;
}) => {
  return (
    <div className={s.container}>
      <ShroomsLogo single="single" />
      <div className={s.wrapper}>
        <ShroomsMenu type={type} />
        <AboutMushroom type={hash} />
      </div>
    </div>
  );
};

export default MeetYourShroomSingle;
