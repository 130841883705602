/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/no-static-element-interactions */

import React from "react";
import * as s from "./index.module.scss";
import arrows from "../../../images/arrows.svg";
import { navigate } from "gatsby";

const ShroomsMenu = ({ type }: { type: string }) => {
  const list = [
    "lion's <b>mane</b>",
    "Turkey <b>tail</b>",
    "<b>Cordyceps</b>",
    "<b>Maitake</b>",
    "<b>Chaga</b>",
    "<b>Reishi</b>",
  ];

  const itemsList = [
    "lion's mane",
    "turkey tail",
    "cordyceps",
    "maitake",
    "chaga",
    "reishi",
  ];

  const hashArray = [
    "lionsmane",
    "turkeytail",
    "cordyceps",
    "maitake",
    "chaga",
    "reishi",
  ];

  return (
    <div className={s.container}>
      <div className={s.listWrapper}>
        {list.map((item, index) => (
          <div
            className={s.wrapper}
            key={index}
            onClick={() => {
              const title = itemsList[index];
              const hash = hashArray[index];
              navigate(`/meet-your-shroom-single/#${hash}`, {
                state: { component: { title } },
              });
            }}
          >
            <img src={arrows} alt="arrow"></img>
            <p
              className={s.listItems}
              dangerouslySetInnerHTML={{ __html: item }}
            ></p>
            {list[index]?.toLowerCase().includes(type?.toLowerCase()) ? (
              <div className={s.line}> </div>
            ) : itemsList[index]
                ?.toLowerCase()
                .includes(type?.toLowerCase()) ? (
              <div className={s.line}> </div>
            ) : null}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ShroomsMenu;
