import React from "react";
import * as s from "./FungiFact.module.scss";
import image from "../../../images/Vectorcircle.png";
import img from "../../../images/VectorCircle(1).png";
import classNames from "classnames";

const cn = classNames.bind(s);

interface IProps {
  fungifact: string[] | undefined;
  type?: string;
}

const FungiFact = ({ fungifact, type }: IProps) => {
  return (
    <div className={cn(s.container, { [s.chaga]: type === "Chaga" })}>
      <img src={image} alt="" className={s.biggerImage}></img>
      <div className={s.wrapper}>
        <p className={s.title}>
          Fun(gi) <span>Facts</span>
        </p>
        <div className={s.list}>
          <ul>
            {fungifact &&
              fungifact.map((f, index: number) => (
                <li key={index} dangerouslySetInnerHTML={{ __html: f }}></li>
              ))}
          </ul>
        </div>
      </div>
      <img src={img} alt="" className={s.smallerImage}></img>
    </div>
  );
};

export default FungiFact;
