import React, { useState } from "react";
import * as s from "./index.module.scss";
import classNames from "classnames";
import FungiFact from "../../atoms/FungiFact/FungiFact";
import Question from "../../atoms/Question/Question";
import { aboutShrooms } from "../../../constants/aboutMushrooms";
import { useEffect } from "react";
import LazyLoad from "react-lazyload";

const cn = classNames.bind(s);

interface IShrooms {
  type: string;
  lat: string;
  benefits1: string[];
  perfect: string[];
  description: string;
  funfact: string[];
  image: string;
  lat1: string;
  image1?: string;
  benefits2: string[];
  link?: string;
}

const AboutMushroom = ({ type }: { type: string }) => {
  const linkWithoutHash = type.substring(1);
  const [shrooms, setShrooms] = useState<IShrooms | undefined>();

  useEffect(() => {
    aboutShrooms.map((shr: IShrooms) => {
      shr.link?.includes(linkWithoutHash)
        ? setShrooms({ ...shrooms, ...shr })
        : null;
    });
  }, [type]);

  return (
    <div className={s.container} id={shrooms?.link}>
      <>
        <p className={s.title}>{shrooms?.type}</p>
        <p className={s.subtitle}>{shrooms?.lat}</p>
        <p
          className={cn(s.subtitle, s.subsubtitle)}
          dangerouslySetInnerHTML={{ __html: shrooms?.lat1 }}
        ></p>

        <p className={s.description}>{shrooms?.description}</p>
        <img src={shrooms?.image} alt=""></img>

        <p className={cn(s.title, s.benefits)}>May support:</p>
        <div className={s.listWrapper}>
          <ul className={s.list}>
            {shrooms?.benefits1 &&
              shrooms?.benefits1.map((item: string, index: number) => (
                <li key={index}>{item}</li>
              ))}
          </ul>
          <ul className={s.list}>
            {shrooms?.benefits2 &&
              shrooms?.benefits2.map((item: string, index: number) => (
                <li key={index}>{item}</li>
              ))}
          </ul>
        </div>

        <FungiFact fungifact={shrooms?.funfact} type={shrooms?.type} />

        <div className={s.contentWrapper}>
          <div className={s.textWrapper}>
            <p className={cn(s.title, s.benefits)}>Example activities: </p>
            <ul className={s.perfect}>
              {shrooms?.perfect &&
                shrooms.perfect.map((p: string, index: number) => (
                  <li key={index}>{p}</li>
                ))}
            </ul>
            <Question
              answer="These statements have not been evaluated by the Food
              and Drug Administration. This product is not intended to diagnose,
              treat, cure or prevent any disease. The views and nutritional
              advice expressed by Troop Nutrition are not intended to be a
              substitute for conventional medical service. If you have a severe
              medical condition, see your physician of choice. Individual
              results may vary."
              question="Disclaimer"
              component="benefits"
            />
          </div>
          <div className={s.imageWrapper}>
            <LazyLoad>
              <img src={shrooms?.image1} alt=""></img>
            </LazyLoad>
          </div>
        </div>
      </>
    </div>
  );
};

export default AboutMushroom;
